<template>
  <div>
    <a class="bx-header__logo bx-header__logo--selbst"
       title="https://www.selbst.de/"
       :href="getAbsoluteUrl('/')">
      <img src="/images/selbst/selbst-logo.svg"
           alt="Selbst ist der Mann Logo"
           width="156"
           height="80">
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    getAbsoluteUrl (url) {
      if (import.meta.env.SSR) return url
      const a = document.createElement('a')
      a.href = url

      return a.href
    }
  }
}
</script>
